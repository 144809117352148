import React, { useEffect, useRef, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import '../App.css';
import Machine from '../assets/images/machine-room23-600w.avif'
import Machines from '../assets/images/machine-room4-2000w.avif'
import CardioRoom from '../assets/images/cardio-room1-600w.avif'
import Cardio from '../assets/images/cardio-room1-2000w.avif'
import FreeWeight from '../assets/images/free-weight-room7-2000w.avif'
import IntroVideo from '../assets/videos/private-gym-intro-movie.mp4'
import Poster from '../assets/images/poster-image-hero.jpg'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboard, faDumbbell, faShapes, faUser } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate } from 'react-router-dom';
import { membershipPackages } from '../assets/membership-options';
import MembershipPackage from '../components/MembershipPackage';
import { HashLink } from 'react-router-hash-link';
import { animated, config, useSpring, useTransition } from 'react-spring';
import { goToMemberships, scrollToSection } from '../utils/ScrollToSection';
import HeadingTyping from '../components/HeadingTyping';

const HomePage = () => {
    const introSectionRef = useRef<null | HTMLDivElement>(null);
    const roomsSectionRef = useRef<null | HTMLDivElement>(null);
    const offersSectionRef = useRef<null | HTMLDivElement>(null);
    const personalTrainingSectionRef = useRef<null | HTMLDivElement>(null);
    const forYouSectionRef = useRef<null | HTMLDivElement>(null);
    const navigate = useNavigate();


    const [showContent, setShowContent] = useState(false);
    const [brightnessStyle, setBrightnessStyle] = useState({filter: 'brightness(100%)'});

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 250) {
                setBrightnessStyle({filter: 'brightness(100%)'});
            } else {
                setBrightnessStyle({filter: 'brightness(30%)'});
            }
        };

        window.addEventListener('scroll', handleScroll);

        const brightnessTimeout = setTimeout(() => {
            setBrightnessStyle({filter: 'brightness(30%)'});
            setShowContent(true);
        }, 1500);

        return () => {
            clearTimeout(brightnessTimeout);
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // Animation state for the content fade-in
    const contentAnimation = useSpring({
        opacity: showContent ? 1 : 0,
        config: config.molasses,
    });

    // Delayed animation for buttons
    const buttonTransitions = useTransition(showContent, {
        from: {opacity: 0},
        enter: {opacity: 1},
        config: {duration: 1000},
        delay: 1000,
    });


    return (
        <>
            <div className="relative">
                <section className="hero-section relative">
                    <div className="video-container">
                        <video
                            autoPlay
                            preload="auto"
                            loop
                            muted
                            playsInline
                            poster={Poster}
                            className={`intro-video absolute inset-0 w-full h-full object-cover brightness-transition`}
                            style={brightnessStyle}
                        >
                            <source src={IntroVideo} type="video/mp4"/>
                            Your browser does not support the video tag.
                        </video>
                    </div>
                    <animated.div
                        style={{...contentAnimation}}
                        className="default-container text-center flex flex-col justify-center items-center h-full w-full z-10 relative"
                    >
                        <h1>Private Gym <span
                            className="font-extralight">Schijndel</span></h1>
                        <hr className="hero-hr"/>
                        <h3 className="text-primary-500 pt-2 pb-16">Geniet van privacy.</h3>
                        {buttonTransitions(
                            (btnProps, btnItem) =>
                                btnItem && (
                                    <div className="space-x-0 md:space-x-4 space-y-4 md:space-y-0">
                                        <Link to={'/membership'}>
                                            <animated.button
                                                style={{...btnProps}}
                                                className="secondary-button"
                                            >

                                                Lid worden

                                            </animated.button>
                                        </Link>

                                        <animated.button
                                            style={{...btnProps}}
                                            onClick={() => scrollToSection(introSectionRef)}
                                            className="primary-button"
                                        >
                                            Meer informatie
                                        </animated.button>

                                    </div>
                                )
                        )}
                    </animated.div>
                </section>

                <div
                    className="md:-mb-16 -mt-16 md:mt-0 pb-12 md:pb-0 static md:absolute inset-0 flex items-end justify-center mx-auto max-w-screen-lg px-8">
                    <div
                        className="relative z-20 pointer-events-auto bg-white shadow-2xl rounded flex flex-col">
                        <div className="sub-menu grid grid-cols-2 md:grid-cols-4 text-primary-500 text-2xl">
                            <button onClick={() => scrollToSection(forYouSectionRef)}>
                                <div className="text-3xl">{<FontAwesomeIcon icon={faUser}/>}</div>
                                <div className="menu-title">Privacy</div>
                                <div className="text-sm text-gray-400">Ervaar de rust</div>
                            </button>

                            <button onClick={() => scrollToSection(roomsSectionRef)}>
                                <div className="text-3xl">{<FontAwesomeIcon icon={faDumbbell}/>}</div>
                                <div className="menu-title">De Ruimtes</div>
                                <div className="text-sm text-gray-400">Ontdek de ruimtes</div>
                            </button>

                            <button onClick={() => scrollToSection(offersSectionRef)}>
                                <div className="text-3xl">{<FontAwesomeIcon icon={faShapes}/>}</div>
                                <div className="menu-title">Lidmaatschap</div>
                                <div className="text-sm text-gray-400">Bekijk de mogelijkheden</div>
                            </button>

                            <button onClick={() => scrollToSection(personalTrainingSectionRef)}>
                                <div className="text-3xl">{<FontAwesomeIcon icon={faClipboard}/>}</div>
                                <div className="menu-title">Trainingsplan</div>
                                <div className="text-sm text-gray-400">Gewoon inbegrepen</div>
                            </button>
                        </div>

                    </div>
                </div>
            </div>

            <section ref={introSectionRef} className="bg-white">
                <div className="default-container py-0 md:py-64">
                    <div
                        className="block md:grid grid-cols-3 items-center space-x-0 md:space-x-12 space-y-12 md:space-y-0">
                        <div className="">
                            <h2 className="text-3xl md:text-5xl tracking-wider">Train in comfort.</h2>
                            <hr className="primary-hr"/>
                            <p className="pt-4">Train exclusief in je eigen privé-trainingsruimte, met een op
                                maat
                                gemaakt trainingsplan dat speciaal voor jou is ontworpen. Elke dag van het jaar geopend
                                van <span className="font-bold text-primary-500">7.00 tot 22.00</span></p>
                        </div>
                        <div className="">
                            <div className="zoom bg-black shadow-2xl rounded flex flex-col items-center">
                                <img className="h-64 object-cover w-full" src={Machine}
                                     alt="Sportschool machine afbeelding" loading="lazy"/>
                                <div className="p-4">
                                    <p className="text-gray-300 py-4 text-sm">Maak gebruik van high-end
                                        trainingsmaterialen.</p>
                                    <Link to={'/contact'}>
                                        <button className="primary-button">Plan een afspraak</button>
                                    </Link>
                                </div>
                            </div>


                        </div>
                        <div className="">
                            <div className="zoom bg-black shadow-2xl rounded flex flex-col items-center">
                                <img className="h-64 object-cover w-full" src={CardioRoom}
                                     alt="Sportschool machine afbeelding" loading="lazy"/>
                                <div className="p-4">
                                    <p className="text-gray-300 py-4 text-sm">Een trainingsplan gemaakt op basis van
                                        jouw behoeften.</p>
                                    <Link to={'/membership'}>
                                        <button className="primary-button">Ontdek de opties</button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section ref={forYouSectionRef} className="pb-14 bg-gray-100">
                <div className="own-room-section">
                    <div className="default-container">
                        <HeadingTyping className="text-7xl text-white tracking-widest py-48">Een ruimte voor u
                            alleen.</HeadingTyping>
                    </div>
                </div>
                <div className="default-container -mt-44">
                    <div className="grid-three-sections">
                        <div className="white-card">
                            <h4>Volledige privacy</h4>
                            <hr className="primary-hr"/>
                            <p className="pt-4">Bij Private Gym Schijndel ervaar je onbeperkt sporten in volledige
                                privacy. Geen drukte, geen afleiding, nooit wachten</p>
                        </div>

                        <div className="white-card">
                            <h4 className="pb-4">Onbeperkt sporten</h4>
                            <p>Bij Private Gym Schijndel ervaar je onbeperkt sporten in
                                volledige privacy. Geen drukte, geen afleiding - alleen jij en je doelen. Elke dag
                                van het jaar van <span
                                    className="font-bold text-primary-500">7.00 tot 22.00</span> beschikbaar.</p>
                        </div>

                        <div className="white-card">
                            <h4 className="pb-4">Persoonlijk trainingsplan</h4>
                            <p>Bij Private Gym Schijndel sta jij als individu centraal, samen met jouw doelen. Zo
                                kun je gericht trainen met een plan en heldere instructies.</p>
                        </div>
                    </div>
                </div>

            </section>

            <section className="bg-gray-100">
                <div className="default-container">
                    <h2 className="text-3xl md:text-6xl tracking-wider">Waar resultaten plaatsvinden.</h2>
                </div>
            </section>

            <section ref={roomsSectionRef}>
                <Carousel interval={8000} infiniteLoop stopOnHover emulateTouch showThumbs={false} showStatus={false}
                          autoPlay preventMovementUntilSwipeScrollTolerance>
                    <div className="select-none">
                        <img alt="de machinekamer" className="carousel-image" src={Machines}/>
                        <div className="black-card carousel-legend">
                            <h4>De Machinekamer</h4>
                            <hr className="primary-hr"/>
                            <p className="text-gray-300 py-8">Ontdek onze geavanceerde krachttrainingsruimte, waar je
                                jouw spierkracht naar nieuwe niveaus kunt tillen. Met moderne krachtapparaten doe je
                                jouw spier- en botversterkende oefeingen en werk je aan een sterker, krachtiger
                                lichaam</p>
                            <HashLink smooth className="primary-button" to={'/rooms#machine-room'}>
                                Ontdek de Machinekamer
                            </HashLink>
                        </div>
                    </div>

                    <div className="select-none">
                        <img alt="de free-weight zone" className="carousel-image" src={FreeWeight}/>
                        <div className="black-card carousel-legend">
                            <h4>Free-weight zone</h4>
                            <hr className="primary-hr"/>
                            <p className="text-gray-300 py-8">Ontdek onze geavanceerde vrije gewichtsruimte, waar je
                                jouw kracht en spierdefinitie naar nieuwe hoogten kunt brengen. Met moderne vrije
                                gewichtsapparatuur voer je spier- en botversterkende oefeningen uit en werk je aan een
                                sterker, krachtiger lichaam.</p>
                            <HashLink smooth className="primary-button" to={'/rooms#free-weight-zone'}>
                                Ontdek de Free-weight zone
                            </HashLink>
                        </div>
                    </div>
                    <div className="select-none">
                        <img alt="de cardioruimte" className="carousel-image" src={Cardio}/>
                        <div className="black-card carousel-legend">
                            <h4>Cardioruimte</h4>
                            <hr className="primary-hr"/>
                            <p className="text-gray-300 py-8">Ontdek onze cardioruimte, waar je je conditie
                                naar nieuwe hoogten kunt stuwen. Met cardio-apparaten bereik je moeiteloos je
                                fitnessdoelen en werk je aan een sterker, energieker lichaam.</p>
                            <HashLink smooth className="primary-button" to={'/rooms#cardio-room'}>
                                Ontdek de Cardioruimte
                            </HashLink>
                        </div>
                    </div>


                </Carousel>
            </section>

            <section ref={offersSectionRef}
                     className="exercise-types bg-gradient-to-b from-white via-primary-400 to-primary-800 py-8 md:py-32">
                <div className="medium-container flex flex-col justify-center items-center w-full">
                    <h2 className="text-3xl md:text-6xl tracking-wide pb-8 md:pb-28">Kijk wat het best bij u past.</h2>
                    <div className="w-full block md:flex space-y-4 md:space-y-0 space-x-0 md:space-x-8">
                        {membershipPackages.map(membership => {
                            return <MembershipPackage key={membership.id} membership={membership}
                                                      isHomePage={true}/>
                        })}
                    </div>
                    <button onClick={() => goToMemberships(navigate)} className="secondary-button text-xl mt-20">Kies
                        hier uw
                        lidmaatschap
                    </button>
                </div>
            </section>

            {/*<section className="bg-black text-center sticky bottom-0 z-20">*/}
            {/*    <div className="mx-auto max-w-screen-xl px-8 flex flex-col md:flex-row space-x-0 md:space-x-4 space-y-4 md:space-y-0*/}
            {/*     justify-center items-center text-white py-4 ">*/}
            {/*        <h4 className="text-sm md:text-xl uppercase">Feestelijke Opening: De huidige prijzen gelden tot 1*/}
            {/*            april 2024</h4>*/}
            {/*        <button onClick={() => goToMemberships(navigate)} className="secondary-button text-sm">Kies*/}
            {/*            pakket*/}
            {/*        </button>*/}
            {/*    </div>*/}
            {/*</section>*/}

            <section ref={personalTrainingSectionRef} className="bg-personal-training py-20">
                <div className="default-container">
                    <h2 className="py-16 pb-24 text-3xl md:text-6xl tracking-wider text-white">Een trainingsschema op
                        maat.</h2>
                    <div className="white-card w-full lg:w-1/2">
                        <h3 className="font-semibold text-3xl md:text-4xl">Gewoon inbegrepen.</h3>
                        <p className="py-8">Een trainingsschema op maat op basis van jouw
                            behoeften en doelen. Hierdoor verhoog je je kans op het behalen van langdurige en duurzame
                            gezondheidswinst.</p>
                        <Link className="primary-button" to={'/membership'}>
                            Bekijk de opties
                        </Link>
                    </div>
                </div>
            </section>

            <section className="bg-white">
                <div className="default-container text-center flex flex-col justify-center items-center">
                    <h2>Neem direct contact met ons op</h2>
                    <p className="text-sm text-gray-500 pb-8 pt-4">Neem vrijblijvend contact op voor een oriënterend
                        gesprek. Een
                        intakegesprek is altijd vrijblijvend.</p>
                    <Link to={'/contact'} className="primary-button">Neem contact op</Link>
                </div>
            </section>

            <section>
                <div className="empty-section-video"></div>
            </section>

        </>
    )
}

export default HomePage;
